import React, { useState, useContext } from "react"
import Image from "next/legacy/image"

import styles from './ListItemCalendarTicket.module.scss'

import { CartContext } from '../../../contexts/CartContext'

import { CartQuantityPicker } from '../../Cart/CartQuantityPicker/CartQuantityPicker'
import { ProductTicketType, ProductType, ProductLeadPriceDisplayType } from '../../../types/ProductType'
import { AddToCartTicketType } from '../../../types/CartType'
import { CalendarTicketPopup } from './CalendarTicketPopup/CalendarTicketPopup'
import { BookingSiteOperated } from "../../Misc/BookingSiteOperated/BookingSiteOperated";

import { YearContext } from "../../../contexts/YearContext"

type ListItemCalendarTicketProps = {
  product: ProductType,
  isDatedTicket: boolean | any,
  leadPrice: ProductLeadPriceDisplayType | undefined,
  isExpressTicket: boolean
}

export function ListItemCalendarTicket({ product, isDatedTicket, leadPrice, isExpressTicket = false }: ListItemCalendarTicketProps ) {
  const [qty, setQty] = useState(0)
  const [adultQty, setAdultQty] = useState(0)
  const [childQty, setChildQty] = useState(0)
  const [popupOpen, setPopupOpen] = useState(false);
  const [dateAdultPrice, setDateAdultPrice] = useState('')
  const [dateChildPrice, setDateChildPrice] = useState('')

  const aboveText = "Park-to-Park"

  const { year } = useContext(YearContext)

  const adultTicketId = product.tickets.find(t => t.type === "Adult")?.ticket_id
  const childTicketId = product.tickets.find(t => t.type === "Child")?.ticket_id

  // Get the adult and child from leadPrice
  const adultLeadPrice = leadPrice?.adultLeadPrice
  const childLeadPrice = leadPrice?.childLeadPrice

  // If no lead price from the lead price API - Use price_from in the products api ticket[] as previous.
  const adultPrice = product.tickets.find(t => t.ticket_id === adultTicketId)?.price_from ?? ''
  const childPrice = product.tickets.find(t => t.ticket_id === childTicketId)?.price_from ?? ''

  const ticket: ProductTicketType | undefined = !isDatedTicket ? product.tickets[0] : undefined
  const adultTicket = isDatedTicket ? product.tickets.find(t => t.ticket_id === adultTicketId) : undefined
  const childTicket = isDatedTicket ? product.tickets.find(t => t.ticket_id === childTicketId) : undefined

  const selectBtnText = 'Select';

  // @todo: Where should the logic for this go? Does this belong in the API?
  type LogoImg = { path: string, alt: string }
  let logos: Array<LogoImg> = []

  if (isDatedTicket) {
    logos.push({ path: '/park-logos/universal-studios.png', alt: 'Universal Studios' })
    logos.push({ path: '/park-logos/islands-of-adventure.png', alt: 'Islands of Adventure' })
    logos.push({ path: '/park-logos/volcano-bay.png', alt: 'Volcano Bay'})
    if (product.id === '333439' || product.id === '336334') {
      logos.push({ path: '/park-logos/epic-universe.png', alt: 'Epic Adventure' })
    }
  } else if (isExpressTicket) {
    if (['344383', '344376', '344370', '344364', '284254', '284257', '284256', '284265', '344382'].includes(product.id)) {
      logos.push({ path: '/park-logos/universal-studios.png', alt: 'Universal Studios' })
    }
    if (['344383', '344376', '344370', '344364', '284255', '284263', '284256', '284265', '344382'].includes(product.id)) {
      logos.push({ path: '/park-logos/islands-of-adventure.png', alt: 'Islands of Adventure' })
    }
    if (['344383', '344376', '344370', '344364', '344368'].includes(product.id)) {
      logos.push({ path: '/park-logos/epic-universe.png', alt: 'Epic Universe' })
    }
  } else {
    if (product.title.includes('Universal Studios')) logos.push({ path: '/park-logos/universal-studios.png', alt: 'Universal Studios' })
    if (product.title.includes('Adventure')) logos.push({ path: '/park-logos/islands-of-adventure.png', alt: 'Islands of Adventure' })
  }

  return (
    <CartContext.Consumer>
      {({cartAPI, cart}) => {
        const addToBasketBtn = (dateId: string, callback?: Function) => {
          return (
            <button
              className={styles.btn}
              disabled={ ( qty < 1 && adultQty < 1 && childQty < 1 ) || cart?.loading }
              onClick={async () => {
                const items: Array<AddToCartTicketType> = []
                if (ticket) items.push({ ticketId: ticket.ticket_id, qty: qty, dateId })
                if (adultTicket) items.push({ ticketId: adultTicket.ticket_id, qty: adultQty, dateId })
                if (childTicket) items.push({ ticketId: childTicket.ticket_id, qty: childQty, dateId })
                if (items.length) await cartAPI?.add(items)
                if (callback) callback()
                setAdultQty(0)
                setChildQty(0)
                setQty(0)
              }}
            >ADD TO BASKET</button>
          )
        }

        const qtyPickerFromPrice = ticket ? <CartQuantityPicker ticketPrice={ticket.price_from} showTicketPrice={true} showTotalPrice={false} qty={qty} setQty={(qty: number) => setQty(qty)} loading={cart?.loading ?? false} leftText="All ages" leftSubText="" /> : null
        const adultQtyPicker = adultTicket ? <CartQuantityPicker ticketPrice={adultLeadPrice ?? adultPrice} showTicketPrice={true} showTotalPrice={false} qty={adultQty} setQty={(qty: number) => setAdultQty(qty)} loading={cart?.loading ?? false} leftText={adultTicket.type + ' ticket:'} leftSubText={adultTicket.type_description} numDays={ product.num_days } /> : null
        const childQtyPicker = childTicket ? <CartQuantityPicker ticketPrice={childLeadPrice ?? childPrice} showTicketPrice={true} showTotalPrice={false} qty={childQty} setQty={(qty: number) => setChildQty(qty)} loading={cart?.loading ?? false} leftText={childTicket.type + ' ticket:'} leftSubText={childTicket.type_description} numDays={ product.num_days } /> : null
        const qtyPickerCalendar = ticket ? <CartQuantityPicker ticketPrice={ticket.price_from} showTicketPrice={false} showTotalPrice={false} qty={qty} setQty={setQty} loading={cart?.loading ?? false} leftText="All ages" leftSubText="" /> : null
        const adultQtyPickerCalendar = adultTicket ? <CartQuantityPicker ticketPrice={dateAdultPrice} showTicketPrice={true} showTotalPrice={false} qty={adultQty} setQty={setAdultQty} loading={cart?.loading ?? false} leftText={adultTicket.type + ' ticket:'} leftSubText={adultTicket.type_description} numDays={ product.num_days } /> : null
        const childQtyPickerCalendar = childTicket ? <CartQuantityPicker ticketPrice={dateChildPrice} showTicketPrice={true} showTotalPrice={false} qty={childQty} setQty={setChildQty} loading={cart?.loading ?? false} leftText={childTicket.type + ' ticket:'} leftSubText={childTicket.type_description} numDays={ product.num_days } /> : null
        const epicUniverseProductIds = ['333439', '336334'];

        return (
          <div className={`${styles.listItemCalendarTicket} ${product.internal.persuasion_ribbon ? styles.hasPersuasion : null} ${epicUniverseProductIds.includes(product.id) && styles.hasEpicRibbon }`}>
            <div className={styles.ticketProductFullWidth}>
              { product.internal.persuasion_ribbon ? <div className={ styles.persuasionRibbon }>{ product.internal.persuasion_ribbon }</div> : null }
              { epicUniverseProductIds.includes(product.id) && <div className={ styles.persuasionRibbon }>Includes Epic Universe</div> }
              {isDatedTicket && <p className={ styles.textAbove }>{ aboveText }</p>}
              <h3>{ product.title }</h3>
              {!isDatedTicket && <p className={ styles.descriptionText }>{ product.desc_short }</p>}
              <div className={ styles.row }>
                {/* Epic Universe tickets 2025 have their own ticket styling. */}
                  {product.id === '333439' ?
                  <div>
                    <div className={ styles.logosHolderEpic }>
                      <span className={ styles.epicDaysText }>14 days</span>
                      <div className={styles.logos}>
                      {logos.map((logo, idx) => {
                        if (logo.alt !== 'Epic Adventure') {
                          return (<span key={logo.path}>
                            {idx > 0 ? <Image src={'/park-logos/plus.png'} width={27} height={60} alt='plus-logo' /> : null}
                            <Image priority src={logo.path} width={83} height={60} alt={logo.alt} />
                            </span>
                          )}
                        })
                      }
                      </div>
                    </div>
                    <div className={styles.andText}>AND</div>
                    <div className={styles.logosHolderEpic}>
                      <span className={ styles.epicDaysText }>1 day</span>
                      <div className={styles.logos}>
                        {logos.map((logo) => {
                          if (logo.alt === 'Epic Adventure') {
                            return (<span key={logo.path}>
                            <Image priority src={logo.path} width={83} height={60} alt={logo.alt} />
                            </span>
                            )}
                          })}
                        </div>
                      </div>
                  </div>
                  :
                  <div className={ styles.logosHolder }>
                    <div className={styles.logos}>
                      {logos.map((logo, idx) => {
                        return (
                        <span key={logo.path}>
                          {idx > 0 ? <Image src={'/park-logos/plus.png'} width={27} height={60} alt='plus-logo' /> : null}
                          <Image priority src={logo.path} width={83} height={60} alt={logo.alt} />
                        </span>
                        )
                      })}
                    </div>
                    { product.internal.persuasion_ribbon ? <p className={ styles.persuasionText }>{ product.internal.persuasion_message }</p> : null }
                  </div>
                  }
                <div className={ styles.qtyPickers }>
                  { adultQtyPicker }
                  { childQtyPicker }
                  { qtyPickerFromPrice }
                </div>
              </div>
            </div>
            <div className={ styles.footer }>
              <div className={styles.footerDetails}>
                <CalendarTicketPopup popupOpen={popupOpen} onClose={() => setPopupOpen(false)} product={product} qtyPicker={qtyPickerCalendar} childQtyPicker={childQtyPickerCalendar} adultQtyPicker={adultQtyPickerCalendar} addToBasketBtn={addToBasketBtn} setPopupOpen={setPopupOpen} setDateAdultPrice={setDateAdultPrice} setDateChildPrice={setDateChildPrice} isDatedTicket={isDatedTicket}/>
                <p className={styles.detailsLink}><a className={styles.modalLink} onClick={() => setPopupOpen(true)}>View Details</a></p>
                <div className={styles.button}>
                  <p>Select a date to see updated pricing</p>
                  <button className={styles.btn} onClick={() => setPopupOpen(true)}>{selectBtnText}</button>
                </div>
              </div>
              <BookingSiteOperated placement="ticket" />
            </div>
          </div>
        )
      }}
    </CartContext.Consumer>
  )
}
